import './App.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ZoomMtg } from '@zoom/meetingsdk';
import HeaderComponent from './HeaderComponent';
import 'reactjs-popup/dist/index.css';
import { Button } from 'antd';

const baseUrl = 'https://coachgate.com/staging4';

function UserComponent() {
  let{lang, meetingRef} = useParams();
  const [errorMessage, setErrorMessage] = useState('');
  const [meetingStarted, setMeetingStarted] = useState(false);
  useEffect(() => {
    checkMeetingStatus();

    // Cleanup function using leaveMeeting
    return () => {
      // ZoomMtg.leaveMeeting();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initMeeting = (meetingId) =>{
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    
    ZoomMtg.init({
      leaveUrl: baseUrl + '/' + lang +'/user/session/'+ meetingRef,
      patchJsMedia: true,
      
      success: async (success) => {
        
        let signature = (await (await generateUserSignature()).json()).signature;
        let meetingData = (await (await getMeetingData()).json()).data;

        ZoomMtg.join({
          sdkKey: 'vNUfywygSpS8RNCf8QAeyA',
          signature: signature,
          meetingNumber: meetingId,
          passWord: '12345',
          userName: meetingData.cart.hide_name?meetingData.user_id:`${meetingData.user.first_name} ${meetingData.user.last_name}`,
          userEmail: '',
          success: (success) => {
            console.log('Join Meeting Success ', success);
          },
          error: (error) => {
            console.log('Join Meeting Error ', error);
          },
        })
      },
      error: (error) => {
        console.log('Init Error ', error);
      }
    });
  }


  const checkMeetingStatus = () =>{
    setInterval(() => {
      fetch(`${baseUrl}/api/coach/check_meeting_status`,{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          lang: lang,
          meetingRef: meetingRef
        })
      })
      .then(response=>response.json())
      .then(response => {
        if(response.meetingstatus === 'new'){
          // alert('Meeting is not started yet. Please wait for the coach to start the meeting.');
          setMeetingStarted(false);
        }
        else if(response.meetingstatus === 'old'){
          joinMeeting();
          clearInterval();
          setMeetingStarted(true);
        }else if(response.meetingstatus === 'expire'){
          setErrorMessage('Meeting is expired.');
        }else{
          setErrorMessage('Meeting is not available');
        }
      })
    }, 5000);
    
  }

  const getMeetingData = () =>{
    return fetch(`${baseUrl}/api/user/get_meeting_data/${meetingRef}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
  }

  const generateUserSignature = () =>{
    return fetch(`${baseUrl}/api/user/generate_user_signature`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lang: lang,
        meetingRef: meetingRef
      })
    })
    
  }

  const joinMeeting = () =>{
    fetch(`${baseUrl}/api/user/join_meeting`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lang: lang,
        meetingRef: meetingRef
      })
    })
    .then(response=>response.json())
    .then(response => {
      if(response.message){
        alert(response.message)
      }else{
        initMeeting(response.meeting_id)
      }
    })
  }

  
  return (
    <>
      <HeaderComponent errorMessage={errorMessage} hasBackbutton={errorMessage?true:false}/>
      {
        !meetingStarted &&
          <div style={{justifyItems:'center',alignContent:'center',marginRight:'30%',marginLeft:'30%'}}>
            <text style={{fontSize:20,fontWeight:'bold'}}>
            Meeting is not started yet. Please wait for the coach to start the meeting.
              </text>
          </div>
      }
      {
        meetingStarted &&
          <div style={{justifyItems:'center',alignContent:'center',flex:1,backgroundColor:'red',marginRight:'50%',marginLeft:'50%'}}>
              <Button type="primary" style={{alignSelf:'center',marginTop:20}}>Start Meeting</Button>
          </div>
      }
    </>
  );
};

export default UserComponent;
