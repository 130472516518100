import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserComponent from './UserComponent';
import CoachComponent from './CoachComponent';

function App() {


  return (
    <BrowserRouter>
      <Routes>
      <Route path="/coach/:lang/:meetingRef" element={<CoachComponent />} />
        <Route path="/user/:lang/:meetingRef" element={<UserComponent />} />
      </Routes>
    </BrowserRouter>
  );


}
export default App;
